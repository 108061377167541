import React, { useState, useEffect, FC } from 'react';

import classNames from 'classnames';
import TruncateMarkup from 'react-truncate-markup';

import uuid from '../../utils/uuid';
import Ellipsis from '../ui/Ellipsis/Ellipsis';

interface TruncateBreakableProps {
  lines: number;
  text?: string;
}

const TruncateBreakable: FC<TruncateBreakableProps> = ({ lines, text }) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [keyPrefix] = useState(uuid());
  const [resizeKey, setResizeKey] = useState(0);

  useEffect(() => {
    const handleResize = (): void => {
      setResizeKey((prevKey) => prevKey + 1);
    };

    window.addEventListener('resize', handleResize);

    return (): void => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  if (!text) {
    return null;
  }

  const textElement = <span className={'breakable'}>{text}</span>;
  const classes = classNames({
    'truncate-breakable--truncated': isTruncated,
  });

  return (
    <span className={classes}>
      <TruncateMarkup
        key={`${keyPrefix}-${resizeKey}`}
        lines={lines}
        ellipsis={<Ellipsis text={textElement} />}
        onTruncate={(truncated): void => {
          setIsTruncated(truncated);
        }}
      >
        {textElement}
      </TruncateMarkup>
    </span>
  );
};

export default TruncateBreakable;
