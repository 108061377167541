import React from 'react';

import moment from 'moment';
import PropTypes from 'prop-types';

import { Table, TBody, THead, Row, Cell } from '../../components/ui/AccordionTable';
import {
  STATUS_CANCELED,
  STATUS_DRAFT,
  STATUS_NEW,
  STATUS_REJECTED,
  STATUS_SETTLEMENT,
  STATUS_TRIP,
  STATUS_UPCOMING_TRIP,
  STATUS_WAITING_FOR_ACCEPTANCE,
} from '../../constants/request';
import trans from '../../trans';
import { AmountFormatter } from '../AmountFormatter';
import SortableCellWrapper from '../Sortable/SortableCellWrapper';
import TruncateBreakable from '../TruncateBreakable/TruncateBreakable';
import UserName from '../UserName/UserName';

export class InvoiceRequestsListTable extends React.Component<any, any> {
  getAcceptorsForStatus(request) {
    if (
      _.indexOf(
        [
          STATUS_DRAFT,
          STATUS_NEW,
          STATUS_WAITING_FOR_ACCEPTANCE,
          STATUS_TRIP,
          STATUS_UPCOMING_TRIP,
          STATUS_CANCELED,
          STATUS_REJECTED,
          STATUS_SETTLEMENT,
        ],
        request.status,
      ) !== -1
    )
      return request.request_acceptors_users_full_names;
    else return request.settlement_acceptors_users_full_names;
  }

  getAcceptors(request) {
    if (
      request.request_acceptors_users_full_names ||
      request.settlement_acceptors_users_full_names
    ) {
      return this.getAcceptorsForStatus(request);
    }

    if (request.acceptors) {
      return request.acceptors.map((item) => `${item.first_name} ${item.last_name}`).join(', ');
    }

    return null;
  }

  render() {
    const { requests, isLoading, getRouteByName, pagination, changePage, sorter, onChangeSorter } =
      this.props;

    return (
      <Table
        className={'table-stylesheet-theme'}
        striped
        pagination={{
          changePage,
          ...pagination,
          align: 'center',
        }}
      >
        <THead>
          <Row>
            <SortableCellWrapper sorter={sorter} onChangeSorter={onChangeSorter} name={'sent_at'}>
              {({ icon }) => (
                <Cell dateCell fixedWidth={130} className={'table-accordion__col__sortable'}>
                  {trans('request.date')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper sorter={sorter} onChangeSorter={onChangeSorter} name={'number'}>
              {({ icon }) => (
                <Cell fixedWidth={115} className={'table-accordion__col__sortable'}>
                  {trans('request.request-id')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'name_translated'}
              style={{ width: '100%' }}
            >
              {({ icon }) => (
                <Cell fixedWidth={'auto'} className={'table-accordion__col__sortable'}>
                  {trans('request.name')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'request_element_accounted_sum_amount'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={140}
                  className={'accounted-sum-amount table-accordion__col__sortable'}
                  alignRight
                >
                  {trans('request.accounted-sum-amount')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'request_element_sum_amount'}
            >
              {({ icon }) => (
                <Cell
                  fixedWidth={175}
                  className={'sum-amount table-accordion__col__sortable'}
                  alignRight
                >
                  {trans('request.sum-amount')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'user_last_name'}
            >
              {({ icon }) => (
                <Cell fixedWidth={130} className={'table-accordion__col__sortable'}>
                  {trans('request.applicant')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'request_acceptors_users_last_names'}
            >
              {({ icon }) => (
                <Cell fixedWidth={130} className={'table-accordion__col__sortable'}>
                  {trans('request.acceptor')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
            <SortableCellWrapper
              sorter={sorter}
              onChangeSorter={onChangeSorter}
              name={'status_translated'}
            >
              {({ icon }) => (
                <Cell fixedWidth={125} className={'table-accordion__col__sortable'}>
                  {trans('request.status')}&nbsp;{icon}
                </Cell>
              )}
            </SortableCellWrapper>
          </Row>
        </THead>
        <TBody isLoading={isLoading}>
          {requests.map((request, i) => {
            const acceptors = this.getAcceptors(request);

            return (
              <Row
                className='request-list__table'
                key={i}
                href={getRouteByName('main', 'invoiceRequestShow', { id: request.slug })}
              >
                <Cell fixedWidth={130}>
                  {request['sent_at'] && (
                    <span>
                      {request['sent_at'] ? moment(request['sent_at']).format('YYYY-MM-DD') : ''}
                    </span>
                  )}
                  {!request['sent_at'] && <span>---</span>}
                </Cell>
                <Cell fixedWidth={115}>{request['uid']}</Cell>
                <Cell fixedWidth={'auto'}>
                  <TruncateBreakable text={request.name} lines={1} />
                </Cell>
                <Cell fixedWidth={140} className={'accounted-sum-amount'} alignRight>
                  <div className='is-allign-end'>
                    <AmountFormatter amount={request['requestElementsAccountedSumAmount']} />
                  </div>
                </Cell>
                <Cell fixedWidth={175} className={'sum-amount'} alignRight>
                  <div className='is-allign-end'>
                    <AmountFormatter amount={request['requestElementsSumAmount']} />
                  </div>
                </Cell>
                <Cell fixedWidth={130}>
                  {request['user'] && (
                    <UserName
                      firstName={request.user.first_name}
                      lastName={request.user.last_name}
                    />
                  )}
                </Cell>
                <Cell fixedWidth={130}>
                  <TruncateBreakable text={acceptors} lines={2} />
                </Cell>
                <Cell fixedWidth={125}>{trans(`global.request-status-${request['status']}`)}</Cell>
              </Row>
            );
          })}
        </TBody>
      </Table>
    );
  }
}

InvoiceRequestsListTable.propTypes = {
  requests: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  getRouteByName: PropTypes.func.isRequired,
  pagination: PropTypes.object.isRequired,
  changePage: PropTypes.func.isRequired,
  sorter: PropTypes.object.isRequired,
  onChangeSorter: PropTypes.func.isRequired,
};
