import React from 'react';

import { isFunction, isUndefined } from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';

import {
  STATUS_ACCEPTANCE_OF_SETTLEMENT,
  STATUS_DRAFT,
  STATUS_SETTLEMENT,
  STATUS_TRIP,
  STATUS_UPCOMING_TRIP,
  STATUS_WAITING_FOR_ACCEPTANCE,
} from '../../constants/request';
import * as constants from '../../constants/request';
import {
  loadOptionsForAcceptors,
  loadOptionsForSettlementAcceptors,
} from '../../store/app/users-for-select';
import trans from '../../trans';
import { Request } from '../../types/request';

import { AddPerson } from './AddPerson';
import { Person } from './Person';

interface RequestStatusPathProps {
  request: Request;
  [key: string]: any;
}

class AbstractRequestStatusPath extends React.PureComponent<RequestStatusPathProps, any> {
  constructor(props) {
    super(props);
  }

  userCan(ability) {
    const { request } = this.props;

    if (!isUndefined(request.abilities)) {
      return request.abilities[ability];
    }
    return null;
  }

  handleRemove(remove, removeSettlement, acceptor) {
    const { settlementAcceptors } = this.props;

    return remove(acceptor).then(() => {
      const settlementAcceptor = settlementAcceptors.find(
        (settlementAcceptor) => settlementAcceptor.slug === acceptor.slug,
      );

      if (isUndefined(settlementAcceptor) && isFunction(removeSettlement)) {
        return removeSettlement(acceptor);
      }
    });
  }

  handleAdd(add, addSettlement, acceptor) {
    const { settlementAcceptors } = this.props;

    add(acceptor);

    const settlementAcceptor = settlementAcceptors.find(
      (settlementAcceptor) => settlementAcceptor.slug === acceptor.slug,
    );
    if (settlementAcceptor && isFunction(addSettlement)) {
      addSettlement(acceptor);
    }
  }

  renderPerson(acceptor, add, remove, removeSettlement, acceptors, canRemove = true) {
    const name = `${acceptor.first_name} ${acceptor.last_name}`;
    return (
      <Person
        options={[
          {
            ...acceptor,
            full_name: acceptor.first_name + ' ' + acceptor.last_name + ' (' + acceptor.email + ')',
          },
          ...acceptors,
        ]}
        handleEdit={add}
        handleRemove={() => this.handleRemove(remove, removeSettlement, acceptor)}
        sid={acceptor.id}
        name={name}
        key={acceptor.id}
        accepted={acceptor.accepted}
        withRemoveAction={canRemove}
        date={acceptor['acceptance_date']}
        comment={acceptor.comment}
      />
    );
  }

  renderAcceptors() {
    const {
      acceptors,
      addAcceptor,
      addSettlementAcceptor,
      request: { acceptors: requestAcceptors, status: requestStatus },
      removeAcceptor,
      removeSettlementAcceptor,
    } = this.props;

    const active = this.userCan('attachAcceptor') === null || this.userCan('attachAcceptor');

    return (
      <ul className='path__element-content-list'>
        {requestAcceptors.map((acceptor) =>
          this.renderPerson(
            acceptor,
            addAcceptor,
            removeAcceptor,
            removeSettlementAcceptor,
            acceptors,
            acceptor.abilities.detachAcceptor,
          ),
        )}
        {(requestStatus === STATUS_DRAFT || requestStatus === STATUS_WAITING_FOR_ACCEPTANCE) && (
          <AddPerson
            active={active}
            options={acceptors}
            handleAddPeople={addAcceptor}
            loadOptions={loadOptionsForAcceptors}
          />
        )}
      </ul>
    );
  }

  renderSettlementAcceptors() {
    const {
      settlementAcceptors,
      addSettlementAcceptor,
      request: { settlementAcceptors: requestSettlementAcceptors, status: requestStatus },
      removeSettlementAcceptor,
    } = this.props;

    const active =
      this.userCan('attachSettlementAcceptor') === null || this.userCan('attachSettlementAcceptor');

    const ifRender =
      requestStatus === STATUS_DRAFT ||
      requestStatus === STATUS_WAITING_FOR_ACCEPTANCE ||
      requestStatus === STATUS_UPCOMING_TRIP ||
      requestStatus === STATUS_TRIP ||
      requestStatus === STATUS_SETTLEMENT ||
      requestStatus === STATUS_ACCEPTANCE_OF_SETTLEMENT;

    return (
      <ul className='path__element-content-list'>
        {requestSettlementAcceptors.map((settlementAcceptor) =>
          this.renderPerson(
            settlementAcceptor,
            addSettlementAcceptor,
            removeSettlementAcceptor,
            null,
            settlementAcceptors,
            settlementAcceptor.abilities.detachSettlementAcceptor,
          ),
        )}
        {ifRender && (
          <AddPerson
            active={active}
            options={settlementAcceptors}
            handleAddPeople={addSettlementAcceptor}
            loadOptions={loadOptionsForSettlementAcceptors}
          />
        )}
      </ul>
    );
  }

  renderERPSender() {
    const {
      request: { sentToERP, erp_accounted_at },
    } = this.props;

    if (sentToERP && sentToERP.user && erp_accounted_at) {
      const { user, date } = sentToERP;

      return (
        <ul className='path__element-content-list'>
          <Person
            sid={user.id}
            name={`${user.first_name} ${user.last_name}`}
            key={user.id}
            accepted='accepted'
            date={erp_accounted_at}
          />
        </ul>
      );
    }

    return null;
  }

  renderRejected() {
    const { request } = this.props;

    if (request.status !== constants.STATUS_REJECTED) {
      return null;
    }

    const rejector = request.acceptors.find(
      (acceptor) => acceptor.accepted === constants.STATUS_REJECTED,
    );

    if (!rejector) {
      return null;
    }

    return (
      <div>
        <div>{trans('trip-request.path-status-rejected')}</div>
        <span>{moment(rejector.acceptance_date).format('D.MM.YYYY, H:mm')}</span>
      </div>
    );
  }

  renderCancelled() {
    const { request } = this.props;

    if (request.status !== constants.STATUS_CANCELED) {
      return null;
    }

    return (
      <div>
        <div>{trans('trip-request.path-status-canceled')}</div>
        <span>{moment(request.canceled_at).format('D.MM.YYYY, H:mm')}</span>
      </div>
    );
  }

  render() {
    throw 'Abstract class.';
  }
}

AbstractRequestStatusPath.propTypes = {
  request: PropTypes.object.isRequired,
  acceptors: PropTypes.array.isRequired,
  settlementAcceptors: PropTypes.array.isRequired,
  addAcceptor: PropTypes.func.isRequired,
  addSettlementAcceptor: PropTypes.func.isRequired,
  removeAcceptor: PropTypes.func.isRequired,
  removeSettlementAcceptor: PropTypes.func.isRequired,
};

export { AbstractRequestStatusPath };
export default { RequestStatusPath: AbstractRequestStatusPath };
