import { Company } from './company';
import { Location } from './location';
import { Mpk } from './mpk';
import { User } from './user';

export enum RequestType {
  TRIP = 'trip',
  EXPENSE = 'expense',
  INVOICE = 'invoice',
}

export enum RequestStatus {
  DRAFT = 'draft',
}

export enum RequestState {
  STATE_REQUEST = 'state_request',
  STATE_SETTLEMENT = 'state_settlement',
}

export interface Request {
  instance_id: number;
  user_id: number;
  project_id?: number;
  mpk_id: number;
  uid?: any;
  slug: string;
  type: RequestType;
  status: string;
  state: RequestState;
  use_prepaid: boolean;
  documents: any[];
  name: string;
  name_short?: string;
  created_at: string;
  purpose?: string;
  purpose_edited: boolean;
  trip_agent: string;
  start_location: Location;
  end_location: Location;
  abilities: RequestAbilities;
  mpk: Mpk;
  company: Company;
  travelers: RequestTraveler[];
  periodic: boolean;
  private: boolean;
  user: User;
  unrequestedElement: any;
  features: {
    [key: string]: boolean;
  };
  valueOfOtherDocuments: {
    amount: number;
    currency: string;
  };
}

export interface RequestTraveler {
  slug: string;
  is_lead: boolean;
  user: User;
}

export interface RequestAbilities {
  view: boolean;
  edit: boolean;
  delete: boolean;
  cancel: boolean;
  assignAccountant: boolean;
  attachAcceptor: boolean;
  attachSettlementAcceptor: boolean;
  sendToAcceptance: boolean;
  accept: boolean;
  returnToImprovement: boolean;
  reject: boolean;
  sendToSettlement: boolean;
  settle: boolean;
  addAndDeleteDocuments: boolean;
  editDocuments: boolean;
  addDocuments: boolean;
  accountDocuments: boolean;
  sendToAcceptanceOfSettlement: boolean;
  acceptSettlement: boolean;
  returnToSettlementImprovement: boolean;
  returnToDecreeWhenTransferError: boolean;
  cancelAssignmentToAccountant: boolean;
  account: boolean;
  sendToERP: boolean;
  writeAnnotation: boolean;
  canSendRemindNotification: boolean;
  viewSettlementSummary: boolean;
  searchOffers: boolean;
  bookOffers: boolean;
  setAsUnrealized: boolean;
  editInstalmentCurrency: boolean;
  tripDidNotStarted: boolean;
  addUnrequestedReservation: boolean;
  canShowUnrequestedDocuments: boolean;
  canEditPurpose: boolean;
  canEditBasicSection: boolean;
  delegationTripSwitch: boolean;
  otherCosts: boolean;
  showInstallments: boolean;
  showTripRequestLumpSum: boolean;
}
